$primary: #59298c; /* MAIN COLOR */
$secondary: #1d5ca2; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Didact+Gothic|Paytone+One');

html,body {
  height: 100%;
}

h1,h2,h3 {
	font-family: 'Paytone One', sans-serif;
}
p {
	font-family: 'Didact Gothic', sans-serif;
	font-size: 1.4em;
}
.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** NAV START **/
.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
				  margin-top: 0;
				  height: 28px;
				  padding: 5px;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: 2px solid white;
	border-radius: $border-radius;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;
	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		text-decoration: none;
		@media (max-width: 1024px) {
			background: transparent;
			border: 2px solid $wht;
			color: $wht;
		}
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn;
		display: block;
		background: $primary;
		width: 75%;
		font-size: 18px;
    	margin: 0 auto;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;
		
		@media (max-width: 990px) {
			font-size: 13px;
		}
		
		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}





select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

.banner	{
	background: url(../img/banner.jpg) no-repeat;
	background-size: cover;
	padding: 150px 0;
	background-position: 50% 100%;
	h1, p {
		color: white;
	}
	h1 {
		font-size: 3.75em;
		text-shadow: 2px 2px black;
		@media (max-width: 1199px) {
			font-size: 2.5em;
		}
		@media (max-width: 991px) {
			font-size: 2em;
		}
	}
	@media (max-width: 1199px) {
		padding: 100px 0;
	}
	@media (max-width: 991px) {
		padding: 50px 0;
		background-position: 100% 100%;
	}
	@media (max-width: 500px) {
		background-position: 80% 100%;
	}
}
a.btn {
	@include btn;
	@media (max-width: 1024px) {
		background: $primary;
		color: $wht;
	}
	&:hover {
		@media (max-width: 1024px) {
			background: $primary;
			color: $wht;
		}
	}
	&:focus {
		border: 2px solid white;
		color: $wht;
		text-decoration: none;
		@media (max-width: 1024px) {
			background: $primary;
			color: $wht;
		}
	}
}
a.btn2 {
	@include btn;
	border: 2px solid $primary;
	background: transparent;
	color: $primary;
	&:hover {
		@media (max-width: 1024px) {
			border: 2px solid $primary;
			background: transparent;
			color: $primary;
			text-decoration: none;
		}
	}
	&:focus {
		border: 2px solid $primary;
		background: transparent;
		color: $primary;
		text-decoration: none;
	}
}
.introBoxContain {
.introBox {
	.textIntro {
		padding: 80px 15px;
		background: #f2f2f2;
		color: $primary;
		@media (max-width: 991px) {
			padding: 40px 15px;
			h1 {
				font-size: 1.75em;
			}
		}
	}
	.introImg1 {
		background: url(../img/nebula.jpg) no-repeat;
		background-size: cover;
		height: 400px;
		background-position: 50%;
		@media (max-width: 991px) {
			background-position: 50% 0%;
		}
		@media (max-width: 767px) {
			height: 300px;
		}
	}
	.introImg2 {
		background: url(../img/couple2.jpg) no-repeat;
		background-size: cover;
		height: 400px;
		background-position: 50%;
		@media (max-width: 991px) {
			background-position: 50% 20%;
		}
		@media (max-width: 767px) {
			height: 300px;
		}
	}
}
}

.midSecImg1 {
	background: url(../img/couple1.jpg) no-repeat;
	background-size: cover;
	background-position: 50%;
	height: 500px;
	@media (max-width: 991px) {
		background-position: 50% 0%;
		height: 400px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}
}

.midSecImg2 {
	background: url(../img/couple3.jpg) no-repeat;
	background-size: cover;
	height: 500px;
	background-position: 50%;
	@media (max-width: 991px) {
		background-position: 50% 0%;
		height: 400px;
	}
	@media (max-width: 767px) {
		height: 300px;
	}
}
.midSecTxt1, .midSecTxt2 {
	padding: 70px;
	@media (max-width: 1199px) {
		padding: 40px;
	}
	@media (max-width: 991px) {
		padding: 20px 15px;
	}
	h1 {
		font-size: 2.75em;
		@media (max-width: 991px) {
			font-size: 2em;
		}
	}
}

.navbar-default {
	background: white;
}


i.fa {
	font-size: 4em;
	color: white;
}

.bg {
	background: $secondary;
	padding: 125px 0;
	h1 {
		color: white;
		font-size: 1.6em;
	}
	.title {
		font-size: 2em;
		padding: 0 15px 40px 15px;
	}
	@media (max-width: 991px) {
		padding: 50px 0;
	}
	.iconBox {
		@media (max-width: 600px) {
			width: 100%;
		}
	}
}

.btmBanner {
	background: url(../img/stars.jpg) no-repeat;
	background-size: cover;
	padding: 100px 0;
	background-attachment: fixed;
	color: white;
	@media (max-width: 1024px) {
		background-attachment: scroll;
	}
}

.top-pad {
	font-family: 'Didact Gothic', sans-serif;
	h1,h2,h3 {
		font-family: 'Paytone One', sans-serif;
	}
	p {
		font-family: 'Didact Gothic', sans-serif;
	}
	li {
		font-family: 'Didact Gothic', sans-serif;
	}
}